export default function blossomCanvas(canvasEl, imgList) {
    var f = canvasEl,
        i,
        r,
        o = imgList,
        s,
        u,
        h,
        e;

    if (f) {
        var c = f.getContext('2d'),
            n = window.innerWidth,
            t = window.innerHeight;
        for (f.width = n, f.height = t, i = 20, i = window.innerWidth > 768 ? 20 : 8, r = [], u = 0; u < i; u++)
            r.push({
                x: Math.random() * n,
                y: Math.random() * t,
                r: Math.random() * 4 + 3,
                d: Math.random() * i,
                imgIndex: Math.floor(Math.random() * 3),
            });
        for (s = [], u = 0; u < o.length; u++) {
            h = new Image();
            h.src = o[u];
            s.push(h);
        }
        function l() {
            var f, u, e;
            for (c.clearRect(0, 0, n, t), f = 0; f < i; f++) {
                u = r[f];
                e = s[u.imgIndex];
                c.drawImage(e, u.x, u.y, u.r * 2, u.r * 2);
            }
            a();
        }
        e = 0;
        function a() {
            var f, u;
            for (e += 0.01, f = 0; f < i; f++) {
                u = r[f];
                u.y += Math.cos(e + u.d) + 1 + u.r / 2;
                u.x += Math.sin(e) * 2;
                (u.x > n + 5 || u.x < -5 || u.y > t) &&
                    (r[f] =
                        f % 3 > 0
                            ? {
                                  x: Math.random() * n,
                                  y: -10,
                                  r: u.r,
                                  d: u.d,
                                  imgIndex: Math.floor(Math.random() * 3),
                              }
                            : Math.sin(e) > 0
                            ? {
                                  x: -5,
                                  y: Math.random() * t,
                                  r: u.r,
                                  d: u.d,
                                  imgIndex: Math.floor(Math.random() * 3),
                              }
                            : {
                                  x: n + 5,
                                  y: Math.random() * t,
                                  r: u.r,
                                  d: u.d,
                                  imgIndex: Math.floor(Math.random() * 3),
                              });
            }
        }
        window.addEventListener('resize', function () {
            n = window.innerWidth;
            t = window.innerHeight;
            f.width = n;
            f.height = t;
        });
        setInterval(l, 45);
    }
}
