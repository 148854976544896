import { css } from '@emotion/css';
import { coinRefresh, rotateAnimation } from 'constants/global.style';
import { colors } from 'constants/theme';

const headerStyles = {
    wrap: css`
        background: #022222;
    `,
    content: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        .header-right {
            position: relative;
            display: flex;
            align-items: center;
            gap: 8px;
            .decor-3 {
                position: absolute;
                right: -8px;
                transform: translateX(100%);
                @media (max-width: 1580px) {
                    display: none;
                }
                height: 70px;
            }
            .decor-1 {
                position: absolute;
                left: -8px;
                transform: translateX(-100%);
                height: 70px;
            }
        }
    `,
    logo: css`
        width: 220px;
        cursor: pointer;
        position: relative;
        img {
            width: 100%;
        }
    `,
    fireworks: css`
        position: absolute;
        z-index: 1000;
        width: 80px;
        top: 0;
        align-self: center;
        margin-left: 60px;
    `,

    userHeader: css`
        display: flex;
        align-items: center;
    `,
    btnLeft: css`
        background: rgba(69, 148, 255, 0.1);
        display: flex;
        align-items: center;
        border-radius: 40px;
        padding: 6px 12px;
        margin-right: 10px;
        cursor: pointer;
    `,
    wrapBtnAccount: css`
        position: relative;
    `,
    btnAccount: css`
        gap: 10px;
        transition: 0.25s;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: 0;
            background: ${colors.white};
            transition: 0.2s;
            transition-delay: 0.2s;
        }
        &.show {
            border-radius: 18px 18px 0 0;
            background: ${colors.white};
            &::before {
                display: block;
                height: 3px;
            }
            p {
                color: initial;
            }
            .arrow-icon {
                transform: rotate(180deg);
            }
        }
    `,
    username: css`
        font-size: 14px;
        color: #f4f4f5;
    `,
    avatar: css`
        width: 24px;
    `,
    arrow: css`
        width: 12px;
        transition: 0.25s;
    `,
    btnBalance: css`
        align-self: stretch;
        position: relative;
        .coin-icon {
            position: absolute;
            width: 18px;

            display: block;
            opacity: 0;
        }
        .refresh-icon {
            width: 18px;
            margin-right: 8px;
        }
        p {
            font-size: 14px;
            color: ${colors.yellow};
        }
    `,
    rotatingBalance: css`
        .refresh-icon {
            animation: ${rotateAnimation} 0.8s linear infinite;
        }
        .coin-icon {
            animation: 1s ease 0s 1 normal forwards running ${coinRefresh};
        }
    `,

    btnAction: css`
        display: flex;
        gap: 10px;
    `,
    noti: css`
        padding: 0 4px;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        img {
            width: 32px;
        }
    `,
    countUnreadMsg: css`
        position: absolute;
        left: calc(50% + 3px);
        top: calc(50% - 16px);

        background: ${colors.red};
        width: 16px;
        height: 16px;
        border-radius: 20px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 10px;
        color: ${colors.white};
        line-height: 0;
    `,
};
export default headerStyles;
