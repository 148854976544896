import { css } from '@emotion/css';
import userApi from 'apis/userApi';
import backgroundVip from 'assets/images/vip/backgroundVip.png';
import lineTitleVip from 'assets/images/vip/lineTitleVip.png';
import { VipDataModal } from 'models/vipModal';
import { useEffect, useState } from 'react';
import { formatNumber } from 'utils/function';

const VipPage = () => {
    const [dataVip, setDataVip] = useState<VipDataModal[]>();

    useEffect(() => {
        userApi.getDataVipPage().then((res) => {
            setDataVip(res.data.userVipSettingInfoList);
        });
    }, []);

    if (!dataVip) return null;

    return (
        <>
            <div className={styles.bannerImg}>
                <img src={require('assets/images/VIP-banner.png')} alt="" />
                <div className={styles.titleVip}>
                    <img src={require('assets/images/VIP-title.png')} alt="" />
                </div>
            </div>

            <div className={styles.item}>
                <div className={styles.vipItem}>
                    <p className={styles.title}>Tiêu Chuẩn Thăng Cấp</p>
                    <div className={styles.containerVip}>
                        <p className={styles.titleRank1}>CẤP BẬC</p>
                        {dataVip.map((item, index) => (
                            <p key={index} className={styles.titleRank}>
                                {item.vipLevelName}
                            </p>
                        ))}
                    </div>
                    <div className={styles.contentItemFather}>
                        <div className={styles.contentItem}>
                            <p className={styles.contentRank1}>TỔNG NẠP</p>
                            {dataVip.map((item, index) => (
                                <p key={index} className={styles.contentRank}>
                                    {formatNumber(item.totalDeposit)}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={styles.vipItem}>
                    <p className={styles.title}>Điều Kiện Bảo Lưu Cấp Bậc</p>
                    <div className={styles.containerVip}>
                        <p className={styles.titleRank1}>CẤP BẬC</p>
                        {dataVip.map((item, index) => (
                            <p key={index} className={styles.titleRank}>
                                {item.vipLevelName}
                            </p>
                        ))}
                    </div>
                    <div className={styles.contentItemFather}>
                        <div className={styles.contentItem}>
                            <p className={styles.contentRank1}>TỔNG NẠP TRONG THÁNG</p>
                            {dataVip.map((item, index) => (
                                <p key={index} className={styles.contentRank}>
                                    {formatNumber(item.minDepositRequire)}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={styles.vipItem}>
                    <p className={styles.title}>Hoàn Trả Alobet Casino</p>
                    <div className={styles.containerVip}>
                        <p className={styles.titleRank1}>CẤP BẬC</p>
                        {dataVip.map((item, index) => (
                            <p key={index} className={styles.titleRank}>
                                {item.vipLevelName}
                            </p>
                        ))}
                    </div>
                    <div className={styles.contentItemFather}>
                        <div className={styles.contentItem}>
                            <p className={styles.contentRank1}>{dataVip[0].refundDetailListAlbCasino[0].gameName.toUpperCase()}</p>
                            {dataVip.map((item, index) => (
                                <p key={index} className={styles.contentRank}>
                                    {item.refundDetailListAlbCasino[0].rate}%
                                </p>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={styles.vipItem}>
                    <p className={styles.title}>Hoàn Trả Mini-Game</p>
                    <div className={styles.containerVip}>
                        <p className={styles.titleRank1}>CẤP BẬC</p>
                        {dataVip.map((item, index) => (
                            <p key={index} className={styles.titleRank}>
                                {item.vipLevelName}
                            </p>
                        ))}
                    </div>
                    <div className={styles.contentItemFather}>
                        {dataVip[0].refundDetailListMiniGame.map((item, index) => (
                            <div key={index} className={styles.contentItem}>
                                <p className={styles.contentRank1}>{item.gameName.toUpperCase()}</p>
                                <p className={styles.contentRank}>{dataVip[0].refundDetailListMiniGame[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[1].refundDetailListMiniGame[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[2].refundDetailListMiniGame[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[3].refundDetailListMiniGame[index].rate}%</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.vipItem}>
                    <p className={styles.title}>Hoàn Trả Casino Quốc Tế</p>
                    <div className={styles.containerVip}>
                        <p className={styles.titleRank1}>CẤP BẬC</p>
                        {dataVip.map((item, index) => (
                            <p key={index} className={styles.titleRank}>
                                {item.vipLevelName}
                            </p>
                        ))}
                    </div>
                    <div className={styles.contentItemFather}>
                        {dataVip[0].refundDetailListCasinoInternational.map((item, index) => (
                            <div key={index} className={styles.contentItem}>
                                <p className={styles.contentRank1}>{item.gameName.toUpperCase()}</p>
                                <p className={styles.contentRank}>{dataVip[0].refundDetailListCasinoInternational[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[1].refundDetailListCasinoInternational[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[2].refundDetailListCasinoInternational[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[3].refundDetailListCasinoInternational[index].rate}%</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.vipItem}>
                    <p className={styles.title}>Hoàn Trả Thể Thao</p>
                    <div className={styles.containerVip}>
                        <p className={styles.titleRank1}>CẤP BẬC</p>
                        {dataVip.map((item, index) => (
                            <p key={index} className={styles.titleRank}>
                                {item.vipLevelName}
                            </p>
                        ))}
                    </div>
                    <div className={styles.contentItemFather}>
                        {dataVip[0].refundDetailListSport.map((item, index) => (
                            <div key={index} className={styles.contentItem}>
                                <p className={styles.contentRank1}>{item.gameName.toUpperCase()}</p>
                                <p className={styles.contentRank}>{dataVip[0].refundDetailListSport[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[1].refundDetailListSport[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[2].refundDetailListSport[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[3].refundDetailListSport[index].rate}%</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.vipItem}>
                    <p className={styles.title}>Hoàn Trả Slot</p>
                    <div className={styles.containerVip}>
                        <p className={styles.titleRank1}>CẤP BẬC</p>
                        {dataVip.map((item, index) => (
                            <p key={index} className={styles.titleRank}>
                                {item.vipLevelName}
                            </p>
                        ))}
                    </div>
                    <div className={styles.contentItemFather}>
                        {dataVip[0].refundDetailListSlot.map((item, index) => (
                            <div key={index} className={styles.contentItem}>
                                <p className={styles.contentRank1}>{item.gameName.toUpperCase()}</p>
                                <p className={styles.contentRank}>{dataVip[0].refundDetailListSlot[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[1].refundDetailListSlot[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[2].refundDetailListSlot[index].rate}%</p>
                                <p className={styles.contentRank}>{dataVip[3].refundDetailListSlot[index].rate}%</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* <img src={require('assets/images/vip/cList.png')} alt="" /> */}

                <div className={styles.footer}>
                    <div className={styles.itemNote}>
                        <p className={styles.dot}>{' • '}</p>
                        <p className={styles.textNote}>
                            Tiền hoàn trả sẽ dựa vào lượng tiền cược của một tuần để kết toán (tiền cược 1 tuần bắt đầu được tính từ 12:00 ngày thứ sáu đến 11:59:59 ngày thứ sáu của tuần tiếp theo),
                            hàng tuần quý khách đặt cược phù hợp với điều kiện khuyến mãi, hệ thống sẽ tự động kết toán. Trước 17:00 của ngày làm việc tiếp theo tiền khuyến mãi sẽ tự động cập nhật vào
                            tài khoản chính đồng thời kèm theo tin nhắn thông báo.
                        </p>
                    </div>
                    <div className={styles.itemNote}>
                        <p className={styles.dot}>{' • '}</p>
                        <p className={styles.textNote}>
                            Các cá nhân tập thể có hành vi lạm dụng chương trình khuyến mãi của công ty dưới mọi hình thức nào thì chúng tôi đều có quyền hủy bỏ và thu hồi những khoản tặng ưu đãi cũng
                            như các nguồn lợi khác có được trong chương trình khuyến mãi.
                        </p>
                    </div>
                    <div className={styles.itemNote}>
                        <p className={styles.dot}>{' • '}</p>
                        <p className={styles.textNote}>
                            Tất cả những chương trình khuyến mãi của trang Web chúng tôi là được thiết kế dành riêng cho người chơi. Trong trường hợp khi người chơi đăng ký thông tin có xảy ra tranh
                            chấp, nhằm đảm bảo lợi ích các bên, tránh tình trạng đánh cắp tài khoản, chúng tôi có quyền yêu cầu người chơi cung cấp đầy đủ các thông tin xác thực để chúng tôi tiến hành
                            các biện pháp xác minh người chơi có đủ điều kiện nhận các ưu đãi trong khuyến mãi của chúng tôi hay không.
                        </p>
                    </div>
                    <div className={styles.itemNote}>
                        <p className={styles.dot}>{' • '}</p>
                        <p className={styles.textNote}>
                            Cùng một tài khoản/Email/điện thoại liên lạc/tài khoản thanh toán (như tài khoản ghi nợ, thẻ tín dụng v.v...) địa chỉ IP/hệ thống vi tính dùng chung thì chỉ được nhận
                            thưởng 1 lần.
                        </p>
                    </div>
                    <div className={styles.itemNote}>
                        <p className={styles.dot}>{' • '}</p>
                        <p className={styles.textNote}>
                            Tất cả hội viên không được lợi dụng các ưu đãi trong khuyến mãi của công ty chúng tôi cung cấp để tiến hành đặt cược vụ lợi dưới mọi hình thức. Đối với trường hợp này thì
                            công ty chúng tôi sẽ có quyền truy vấn hành vi ác ý làm ảnh hưởng đến hệ thống vận hành bình thường của chúng tôi.
                        </p>
                    </div>
                    <div className={styles.itemNote}>
                        <p className={styles.dot}>{' • '}</p>
                        <p className={styles.textNote}>
                            Nghiêm cấm lợi dụng các ưu đãi trong chương trình khuyến mãi để thực hiện các hành vi đặt cược như sau: đặt cược trên dưới, đặt cược tập thể, lợi dụng trang Web khác để
                            tiến hành đăt cược thu lợi nhuận.
                        </p>
                    </div>
                    <div className={styles.itemNote}>
                        <p className={styles.dot}>{' • '}</p>
                        <p className={styles.textNote}>
                            Vào những ngày đầu tháng hệ thống sẽ tự động xét duyệt nâng cấp cấp bậc hội viên, nếu phù hợp với điều kiện nâng cấp hệ thống sẽ tự động điều chỉnh cấp bậc và tiền hoàn
                            trả, đồng thời gửi tin nhắn để thông báo.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

const styles = {
    bannerImg: css`
        display: flex;
        position: relative;
        img {
            width: 100%;
        }
    `,
    titleVip: css`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    `,
    item: css`
        img {
            width: 100%;
            margin-bottom: 5vh;
        }
        margin-left: 15vw;
        margin-right: 15vw;
        margin-top: 10vh;
    `,
    vipItem: css`
        background-image: url(${backgroundVip});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-position: top;
        width: 100%;
        padding-bottom: 12px;
        border-radius: 30px;
        margin-bottom: 7vh;
    `,
    title: css`
        color: #ffffff;
        font-weight: bold;
        font-family: 'Lato', sans-serif;
        font-size: 30px;
        padding-top: 1vh;
        padding-left: 2.4vw;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    `,
    containerVip: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 36px;
        margin-right: 12px;
        margin-left: 12px;
        margin-top: 2.5vh;

        background-image: url(${lineTitleVip});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-position: top;
        border-radius: 10px;
    `,
    titleRank: css`
        color: #492502;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        text-align: center;
        width: 10vw;
        line-height: 5vh;
    `,
    titleRank1: css`
        color: #492502;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        width: 15vw;
        line-height: 5vh;
    `,
    contentRank: css`
        color: white;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        text-align: center;
        width: 10vw;
        line-height: 5vh;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    `,
    contentRank1: css`
        color: white;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        width: 15vw;
        line-height: 5vh;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    `,

    contentItemFather: css`
        background-color: #5c4d27cc;
        margin: 0px 12px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    `,
    contentItem: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 24px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 24px;
            right: 24px;
            border-bottom: 1px solid #f2ce7a;
        }

        &:last-child::after {
            border-bottom: none;
        }
    `,
    itemNote: css`
        background-color: #053a3a;
        border-width: 1px;
        border-color: #106f6f;
        border-style: solid;
        padding: 12px 20px;
        border-radius: 12px;
        display: flex;
        gap: 8px;
        margin-bottom: 20px;
    `,
    textNote: css`
        color: white;
        font-size: 14px;
        font-family: 'Lato', sans-serif;
        font-weight: medium;
        line-height: 20px;
    `,
    dot: css`
        color: white;
        font-size: 14px;
        font-family: 'Lato', sans-serif;
        font-weight: medium;
        line-height: 20px;
    `,
    footer: css`
        padding-bottom: 30px;
    `,
};

export default VipPage;
