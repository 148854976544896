import { css } from '@emotion/css';
import { rotatingLight, showHideEffect } from 'constants/global.style';

interface Props {
    onLaunchGame?: () => void;
}

export default function VqmmIcon(props: Props) {
    const { onLaunchGame } = props;

    return (
        <div onClick={onLaunchGame} className={styles.wrapIcon}>
            <div className="lucky-spin">
                <img className="vqmm" src={require('assets/images/lucky-win/vqmm.png')} alt="" />
                <img className="vqmm-1" src={require('assets/images/lucky-win/vqmm-1.png')} alt="" />
                <img className="vqmm-2" src={require('assets/images/lucky-win/vqmm-2.png')} alt="" />
                <img className="vqmm-3" src={require('assets/images/lucky-win/vqmm-3.png')} alt="" />
            </div>
        </div>
    );
}

const styles = {
    wrapIcon: css`
        position: fixed;
        z-index: 998;
        bottom: 150px;
        left: 25px;
        cursor: pointer;
        .lucky-spin {
            position: relative;
            height: 100%;
        }
        .vqmm {
            width: 100px;
        }
        .vqmm-1 {
            position: absolute;
            width: 76px;
            top: 11px;
            left: 12px;
            animation: ${rotatingLight} 2s linear infinite;
        }
        .vqmm-2 {
            position: absolute;
            width: 100%;
            left: 6px;
            top: 17%;
        }
        .vqmm-3 {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 10px;
            animation: ${showHideEffect} 0.5s ease-in-out infinite;
        }
    `,
};
