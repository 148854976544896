import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import globalStyle from 'constants/global.style';
// import Winwheel from 'winwheel';
import { useEffect, useRef, useState } from 'react';
import luckySpinApi from 'apis/luckySpinApi';
import alertMsg from 'components/alert-message/alert-message';
import { HistoryLuckWheelItem, HistoryLuckySpinItem, ItemLuckyWheelSlide, LuckWinItemResponse, LuckyWinEventInfo, LuckyWinItem } from 'models/luckyWhellMoel';
import Modal from 'components/modal/Modal';
import 'assets/js/TweenMax.min';
import Winwheel from 'assets/js/Winwheel';
import moment from 'moment';
import styles from './luck-win.style';
import { Navigation } from 'swiper/modules';
import { v4 as uuidv4 } from 'uuid';
import audioSpin from 'assets/audios/wheel-spinning.mp3';
import audioHappy from 'assets/audios/sound-happy.mp3';
import { RootState, useAppSelector } from 'cache/store';
let idInterval: any;
const defaultWin: LuckyWinItem = { text: 'CHÚC MỪNG NĂM MỚI', image: '', numberorder: 0, corresponding: 0 };

const LuckyWin = () => {
    const theWheelRef = useRef<any>(null);
    const historyWinListInit = useRef<HistoryLuckWheelItem[]>([]);
    const winNumberOrder = useRef<number | null>(null);
    const prizeSpinList = useRef<LuckyWinItem[]>([]);
    const audioSpinningRef = useRef(new Audio(audioSpin));
    const audioHappyRef = useRef(new Audio(audioHappy));

    const btnPrevSlide = useRef<HTMLButtonElement>(null);
    const btnNextSlide = useRef<HTMLButtonElement>(null);

    const { userInfo } = useAppSelector((state: RootState) => state.auth);

    const [numberSpin, setNumberSpin] = useState(0);
    const [isSpinning, setIsSpinning] = useState(false);
    const [eventInfo, setEventInfo] = useState({} as LuckyWinEventInfo);
    const [giftList, setGiftList] = useState<LuckyWinItem[]>([]);
    const [giftSlide, setGiftSlide] = useState<ItemLuckyWheelSlide[]>([]);
    const [giftShowPopup, setGiftShowPopup] = useState<LuckyWinItem | null>(null);
    const [historyWinList, setHistoryWinList] = useState<HistoryLuckWheelItem[]>([]);

    const [showHistory, setShowHistory] = useState(false);
    const [showRule, setShowRule] = useState(false);

    useEffect(() => {
        const initLuckySpin = async () => {
            try {
                const { data } = await luckySpinApi.initLuckySpin({});
                const dataEvents: LuckWinItemResponse[] = (data.items as LuckWinItemResponse[]).sort((a, b) => a.numberorder - b.numberorder);
                setEventInfo(data.event);
                let imagesloaded = 0;
                const imagesList = [];
                const luckySlide = [];
                const segments: LuckyWinItem[] = [];
                for (let i = 0; i < dataEvents.length; i++) {
                    const itemSpin: LuckyWinItem = {
                        image: dataEvents[i].url,
                        imageWin: dataEvents[i].urlwebswipe,
                        text: dataEvents[i].prize,
                        numberorder: dataEvents[i].numberorder,
                        corresponding: dataEvents[i].corresponding,
                    };

                    if (dataEvents[i].urlwebswipe) {
                        const itemSwiper: ItemLuckyWheelSlide = {
                            url: dataEvents[i].urlwebswipe,
                            name: dataEvents[i].prize,
                        };
                        luckySlide.push(itemSwiper);
                    }

                    const img = new Image();
                    img.src = dataEvents[i].url;
                    segments.push(itemSpin);
                    imagesList.push(img);
                }

                prizeSpinList.current = segments;
                setGiftSlide(luckySlide);
                setGiftList(segments);

                const handleImageComplete = () => {
                    imagesloaded++;
                    if (imagesloaded === imagesList.length) {
                        drawWheel(segments);
                    }
                };

                imagesList.forEach(function (img) {
                    if (img.complete) {
                        handleImageComplete();
                    } else {
                        img.onload = handleImageComplete;
                    }
                });
            } catch (error) {
                console.log(error);
            }
        };
        initLuckySpin();

        return () => {
            if (audioSpinningRef.current.duration > 0 && !audioSpinningRef.current.paused) {
                audioSpinningRef.current.pause();
            }
        };
    }, []);

    useEffect(() => {
        const getNumberSpin = async () => {
            try {
                const { data } = await luckySpinApi.getTimesSpin({});
                if (data.msg === 'success') {
                    setNumberSpin(data.number);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getNumberSpin();
        getHistoryWin();
    }, []);

    const getHistoryWin = async () => {
        setHistoryWinList([]);
        try {
            const { data }: any = await luckySpinApi.historyWinList({});
            historyWinListInit.current = data || [];
            randomHistoryWinList(6);

            idInterval = setInterval(() => {
                randomHistoryWinList(1);
            }, 5000);
        } catch (error) {
            console.log(error);
        }
    };

    const randomHistoryWinList = (quantity: number) => {
        if (historyWinListInit.current.length > 0) {
            for (let i = 0; i < quantity; i++) {
                //random index history win list
                const idxSelected = randomIndex(historyWinListInit.current.length);
                handleHistoryItem(idxSelected);
            }
        }
    };

    const handleHistoryItem = (idx: number) => {
        const itemSelected = historyWinListInit.current[idx];

        if (itemSelected) {
            historyWinListInit.current = historyWinListInit.current.filter((item) => item.id !== itemSelected.id);
            //reset history win list init
            if (historyWinListInit.current.length === 0) {
                idInterval && clearInterval(idInterval);
                getHistoryWin();
            }
            setHistoryWinList((list) => [itemSelected, ...list]);
        }
    };

    const randomIndex = (max: number) => {
        return Math.floor(Math.random() * (max + 1));
    };

    const drawWheel = (segments: LuckyWinItem[]) => {
        theWheelRef.current = new Winwheel({
            canvasId: 'spinWheel',
            numSegments: segments.length, // Specify number of segments.
            textFontSize: 16, // Set font size accordingly.
            segments: segments,
            drawMode: 'segmentImage',
            strokeStyle: 'green',
            // Note animation properties passed in constructor parameters.
            animation: {
                type: 'spinToStop', // Type of animation.
                duration: 18, // How long the animation is to take in seconds.
                spins: 6, // The number of complete 360 degree rotations the wheel is to do.
                callbackFinished: showPrize,
            },
        });
    };

    const excuteSpin = async () => {
        if (numberSpin === 0) {
            alertMsg(`Bạn chưa đủ điều kiện nhận lượt quay may mắn hoặc đã tham gia đủ tối đa ${eventInfo.maxspins} lượt/ngày`);
            return;
        }
        audioSpinningRef.current.play();
        setIsSpinning(true);

        try {
            const { data } = await luckySpinApi.excuteSpin({});
            if (!data.msg) {
                setNumberSpin((value) => value - 1); // trừ 1 lần quay thưởng

                let rol = 360 / giftList.length; // chiều rộng của 1 giải thưởng trong vòng quay
                let winorder = Number(data.winorder); // giá trị giải thưởng từ lấy từ api
                let indexMatch = giftList.findIndex((item) => item.numberorder === winorder); // tìm vị trí index của giải thưởng trúng gỉải
                winNumberOrder.current = winorder;
                if (indexMatch < 0) {
                    // không tìm thấy giải thưởng phù hợp, gán vào giải mặc định
                    indexMatch = giftList.findIndex((item) => item.numberorder === eventInfo.defaulwinorder);
                } else {
                    const priceGift: number = giftList[indexMatch].corresponding;
                    //gán giải "may mắn lần sau" khi quay trúng giải thưởng ảo
                    if (priceGift > 5000000) {
                        indexMatch = giftList.findIndex((item) => item.numberorder === eventInfo.defaulwinorder);
                    }
                }

                if (theWheelRef.current) {
                    theWheelRef.current.rotationAngle = 0;
                    theWheelRef.current.draw();
                    theWheelRef.current.animation.stopAngle = rol * (indexMatch + 1) - rol / 2;
                    theWheelRef.current.startAnimation();
                }
            } else {
                alertMsg(data.msg);
            }

            // let rol = 360 / giftList.length; // chiều rộng của 1 giải thưởng trong vòng quay
            // winNumberOrder.current = 8;
            // if (theWheelRef.current) {
            //     theWheelRef.current.rotationAngle = 0;
            //     theWheelRef.current.draw();
            //     theWheelRef.current.animation.stopAngle = rol * (8 + 1) - rol / 2;
            //     theWheelRef.current.startAnimation();
            // }
        } catch (error) {
            console.log(error);
            setIsSpinning(false);
        }
    };

    const showPrize = () => {
        setIsSpinning(false);
        let winItem = prizeSpinList.current.find((item) => item.numberorder === winNumberOrder.current);
        console.log(winItem);
        if (!winItem) winItem = defaultWin;
        else {
            if (winItem.corresponding > 5000000) {
                winItem = defaultWin;
            }
        }

        if (winItem.numberorder !== eventInfo.defaulwinorder) {
            audioHappyRef.current.play(); //play audio trúng giải
            const newUserWin = {
                customer: fomartNameUser(userInfo.loginname),
                id: uuidv4(),
                prizename: winItem.text,
                timespin: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            };
            setHistoryWinList((list) => [newUserWin, ...list]);
        }

        setGiftShowPopup(winItem);
    };

    const fomartNameUser = (userName: string) => {
        const lengName = userName.length;
        let firstText = '';
        let lastText = '';
        if (lengName > 4) {
            firstText = userName.substr(0, 2);
            lastText = userName.substr(-2);
            return firstText + '*****' + lastText;
        } else {
            firstText = userName.substr(0, 2);
            lastText = userName.substr(-1);
            return firstText + '**' + lastText;
        }
    };

    return (
        <div className={styles.wrapPage}>
            <div className={globalStyle.container}>
                <div className={styles.wrapContent}>
                    <div className="prize-wrap">
                        <button type="button" className="btn-top btn-rule" onClick={() => setShowRule(true)}>
                            <img src={require('assets/images/lucky-win/btn-rule.png')} alt="" />
                        </button>
                        <div className="prize-content">
                            <img src={require('assets/images/lucky-win/table-prize.png')} alt="" />

                            <div className="slide-content">
                                {/* <Swiper loop> */}
                                <Swiper
                                    autoplay={{ delay: 2000 }}
                                    modules={[Autoplay, Navigation]}
                                    navigation={{
                                        prevEl: btnPrevSlide.current,
                                        nextEl: btnNextSlide.current,
                                    }}
                                    speed={600}
                                    loop
                                >
                                    {giftSlide.map((item) => (
                                        <SwiperSlide key={item.name}>
                                            <div className="prize-item">
                                                <img src={item.url} alt="" loading="lazy" />
                                                <p>{item.name}</p>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                            <button className="btn-navigate-slide prev" type="button" ref={btnPrevSlide}>
                                <img src={require('assets/images/lucky-win/prev.png')} alt="" />
                            </button>
                            <button className="btn-navigate-slide next" type="button" ref={btnNextSlide}>
                                <img src={require('assets/images/lucky-win/next.png')} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className="lucky-spin">
                        <img className="decor-item decor-1" src={require('assets/images/lucky-win/decor-wheel-1.png')} alt="" />
                        <img className="decor-item decor-2" src={require('assets/images/lucky-win/decor-wheel-2.png')} alt="" />
                        <img className="lucky-bg" src={require('assets/images/lucky-win/wheel.png')} alt="" />
                        <canvas id="spinWheel" width="635" height="635"></canvas>
                        <div className="spin-wrap">
                            <div className="spin-inner">
                                <img className="spin-icon" src={require('assets/images/lucky-win/spin.png')} alt="" />
                                <button type="button" className={`btn-excute ${isSpinning ? 'disabled' : ''}`} onClick={excuteSpin}>
                                    <p className={globalStyle.textGradient('180deg', '#FFE48E', '#FFC900')}>Quay!</p>
                                    <div className="times">
                                        <img src={require('assets/images/lucky-win/bg-times.png')} alt="" />
                                        <span>{numberSpin > 0 ? `${numberSpin} lượt` : 'Hết lượt'}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="winner-wrap">
                        <button type="button" className="btn-top btn-history" onClick={() => setShowHistory(true)}>
                            <img src={require('assets/images/lucky-win/btn-history.png')} alt="" />
                        </button>
                        <div className="winner-content">
                            <img src={require('assets/images/lucky-win/table-winner.png')} alt="" />
                            <div className="winner-list">
                                {historyWinList.map((item, index) => (
                                    <div className="winner-item" key={index}>
                                        <div className="left">
                                            <p className="nickname">{item.customer}</p>
                                            <p className="prize-name">{item.prizename}</p>
                                        </div>
                                        <p className="time-spin">{moment(item.timespin).format('DD/MM/YYYY HH:mm')}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isShow={Boolean(giftShowPopup)} onClose={() => setGiftShowPopup(null)} backdropColor="rgba(0, 0, 0, 0.5)" closeClickBackdrop width="600px">
                <div className={styles.happyPopup}>
                    <img className="light-effect" src={require('assets/images/lucky-win/light-wheel.png')} alt="" />
                    <img className="popup-bg" src={require('assets/images/lucky-win/popup.png')} alt="" />
                    {giftShowPopup?.numberorder !== 6 && <img className="text-happy" src={require('assets/images/lucky-win/text-happy.png')} alt="" />}
                    {giftShowPopup && (
                        <div className="gift-info">
                            {giftShowPopup.imageWin && <img src={giftShowPopup.imageWin} alt="" />}
                            <p>{giftShowPopup.text}</p>
                        </div>
                    )}
                </div>
            </Modal>

            {/* modal show history */}
            <Modal isShow={showHistory} onClose={() => setShowHistory(false)} backdropColor="rgba(0, 0, 0, 0.5)" closeClickBackdrop width="400px">
                <div className={styles.wrapHistory}>
                    <div className="history-inner">
                        <div className="heading">
                            <img src={require('assets/images/lucky-win/btn-history.png')} alt="" />
                        </div>
                        <div className="history-content">
                            <HistorySpin isSpinning={isSpinning} />
                        </div>
                        <button type="button" className="btn-close-popup" onClick={() => setShowHistory(false)}>
                            ĐÓNG
                        </button>
                    </div>
                </div>
            </Modal>

            {/* modal show rule */}
            <Modal isShow={showRule} onClose={() => setShowRule(false)} backdropColor="rgba(0, 0, 0, 0.5)" closeClickBackdrop width="400px">
                <div className={styles.wrapHistory}>
                    <div className="history-inner">
                        <div className="heading">
                            <img src={require('assets/images/lucky-win/btn-rule.png')} alt="" />
                        </div>
                        <div className="history-content">
                            <div className="rule-content">
                                <p>
                                    <span className="title">Trong thời gian khuyến mãi, với mỗi 1,000,000 VND tiền nạp, Thành viên sẽ nhận được 1 lượt quay để tham gia vào vòng quay may mắn. </span>
                                    <span>1. Thành viên có thể nhận tối đa 25 lượt quay/ngày. </span>
                                    <span>
                                        2. Thành viên có 24 giờ bắt đầu từ 00:00 để hoàn thành số tiền nạp 1,000,000 VND và nhận 1 lượt quay may mắn. Tiền nạp sẽ không được cộng dồn cho ngày hôm sau.
                                    </span>
                                    <span>3. Nếu người chơi không sử dụng lượt quay của mình thì đến 00:00:01 ngày hôm sau toàn bộ số lượt trong nhận được ngày trước đó sẽ bị vô hiệu hóa.</span>
                                    <span>4. Giải thưởng là tiền thưởng sẽ được tự động cập nhập vào tài khoản chính của Thành viên ngay khi Thành viên nhận được giải thưởng từ lượt quay đó</span>
                                    <span>5. Giải thưởng là Tiền thưởng cần trải qua 1 vòng cược tại bất kỳ sản phẩm nào.</span>
                                    <span>
                                        6. Đối với giải thưởng dưới dạng hiện vật, Thành viên cần liên hệ với ALOBET88 trong vòng 2 ngày và cung cấp đầy đủ các thông tin yêu cầu để ALOBET88 có thể
                                        thực hiện việc liên lạc và giao hàng gồm: thông tin số điện thoại chính xác (có kết nối Zalo, địa chỉ nhận quà, ảnh 2 mặt CMND).
                                    </span>
                                    <span>
                                        7. Thời gian trả phần thưởng là hiện vật trong vòng 14 ngày kể từ ngày Thành viên liên hệ thành công với ALOBET88 về các thông tin quà tặng và thông tin nhận
                                        quà. Sau khi nhận được phần thưởng, Thành viên vui lòng liên hệ với Bộ Phận Chăm Sóc Khách Hàng của ALOBET88.
                                    </span>
                                    <span>8. Trong vòng 2 ngày mà Thành viên không liên hệ với ALOBET88 để nhận quà trúng thưởng thì ALOBET88 sẽ tự động hủy quà tặng của Quý khách.</span>
                                    <span>9. Giải thưởng là hiện vật có thể quy đổi thành tiền thưởng. Giá trị quy đổi bằng 80% giá trị hiện vật – Không cần cược lại.</span>
                                    <span>
                                        10. ALOBET88 có quyền thay đổi, chỉnh sửa hoặc hủy bỏ chương trình Khuyến mãi bất kỳ lúc nào mà không cần có sự thông báo hay đền bù từ phía Ban tổ chức.
                                    </span>
                                    <span>
                                        11. ALOBET88 có quyền sửa đổi, thu hồi, hủy bỏ hay từ chối bất kỳ phần thưởng khuyến mãi nào nếu phát hiện bất kỳ hành vi gian lận và lạm dụng chương trình.
                                    </span>
                                    <span>12. Các Quy định chung về tất cả các Khuyến mãi của ALOBET88 được áp dụng.</span>
                                </p>
                            </div>
                        </div>
                        <button type="button" className="btn-close-popup" onClick={() => setShowRule(false)}>
                            ĐÓNG
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

interface HistorySpinProps {
    isSpinning: boolean;
}

const HistorySpin = (props: HistorySpinProps) => {
    const { isSpinning } = props;
    const [historyList, setHistoryList] = useState<HistoryLuckySpinItem[]>([]);
    console.log(historyList);
    useEffect(() => {
        const getHistorySpin = async () => {
            try {
                const { data }: any = await luckySpinApi.historySpin({});
                if (isSpinning) {
                    if (Array.isArray(data) && data.length > 0) {
                        let firstHis = data[0];
                        data.shift();
                        firstHis.prizename = 'Đang mở thưởng...';
                        setHistoryList([firstHis, ...data]);
                    }
                } else {
                    setHistoryList(data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getHistorySpin();
    }, []);

    return (
        <div className={styles.historySpinWrap}>
            <div className="history-item history-heading">
                <p>Thời Gian</p>
                <p>Giải Thưởng</p>
            </div>
            <div className="history-list">
                {historyList.map((item) => (
                    <div className="history-item" key={item.id}>
                        <p>{moment(item.timespin).format('DD/MM/YYYY HH:mm')}</p>
                        <p>{item.prizename}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LuckyWin;
