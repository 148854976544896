import serviceType from 'constants/serviceType';
import axiosClient from './aixosClient';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';
import { BankCardAddPayload } from 'models/bankModel';
import { AgentSignPayload, AgentRegLinkPayload, AgentMemberPayload } from 'models/agentModel';
import { FundTransSearchPayload, BonusReportSearchPayload, HistoryBetPayload } from 'models/fundTransferModel';
import { StatisticSearchPayload, StatsGeneralResponse, StatsAgencyPayload, StatsDailyPayload } from 'models/statisticModel';
import { WalletHistoryPayload } from 'models/walletModel';
import { PayloadUpdateUser } from 'models/userInfoModel';
const userApi = {
    changeNickname(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'LoginBean_changeNickName';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    changeBirthday(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'LoginBean_changeBirthday';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    phoneCheck(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'PersonPwdBean_phoneCheck';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    confirmNumPhone(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'PersonPwdBean_confirmNumPhone';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    editPassWord(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    //bank api
    getBankPersonList(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'PersonBankCardBean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    getBankList(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'GetWithdrawalBanks';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    lockBankCardPerson(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'PersonBankCardBean_lockBankcard';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    addBankcardCheck(payload: BankCardAddPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'PersonBankCardBean_addBankcardCheck';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    addBankcardCheckAdd(payload: BankCardAddPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'PersonBankCardBean_addBankcard';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    //gift code
    sendGiftCode(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'EventGiftCode_send';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    //agent
    agentRegisterInit(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'AgentRegBean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    agentRegisterSave(payload: AgentSignPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'AgentRegBean_save';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    agentUserSearch(payload: AgentMemberPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'AgentUserSearchBean_btnSearch';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    agentUserAutoRegList(payload: { flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'AgentAutoRegBean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    agentCreateLink(payload: AgentRegLinkPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'AgentAutoRegBean_save';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    initAgentZbBean(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'AgentZbBean_init';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    // biến động số dư
    balanceAgentZbBean(payload: FundTransSearchPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'AgentZbBean_btnSearch';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    balanceChangeSearch(payload: FundTransSearchPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'BalanceChangeBean_btnSearch';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    bonusReportBean(payload: BonusReportSearchPayload, type: 'btnSearch' | 'inputPage'): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = `BonusReportBean_${type}`;
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    //lịch sử cược
    gameRecordInit(payload: { flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'GameRecordBean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    gameRecordSearch(payload: HistoryBetPayload, type: 'mobileSearch' | 'inputPage'): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = `GameRecordBean_${type}`;
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    personMessageSearch(payload: { flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'PersonMessageBean_btnSearch';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    personMessageView(payload: { id: number; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'PersonMessageBean_view';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    // thống kê
    reportOverallSearch(payload: StatisticSearchPayload): Promise<AxiosResponse<BaseResponse<StatsGeneralResponse>>> {
        payload.flag = 'OverallReport_main';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    //history wallet
    depositSearch(payload: WalletHistoryPayload, type: 'btnSearch' | 'inputPage'): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = `DepositSearchBean_${type}`;
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    withdrawalSearch(payload: WalletHistoryPayload, type: 'btnSearch' | 'inputPage'): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = `WithdrawalSearchBean_${type}`;
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    reportWinLossSearch(payload: StatsAgencyPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'ReportWinLossBean_bntSearch';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    reportWinLoss2List(payload: StatsDailyPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'ReportWinLoss2Bean_btnSearch';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    updateNewUserInfo(payload: PayloadUpdateUser): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'LoginBean_updateUserInfo';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    getFirebaseConfig(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'GetFBConfig';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    listSportView(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'SportViewBean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    // vip page
    getDataVipPage(): Promise<AxiosResponse<BaseResponse<any>>> {
        return axiosClient.post(serviceType.USER_SERVICE, { flag: 'UserVipBean_init' });
    },
};
export default userApi;
