import serviceType from 'constants/serviceType';
import axiosClient from './aixosClient';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';

const luckySpinApi = {
    initLuckySpin(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'EventSpin_initSpin';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    getTimesSpin(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'EventSpin_numberSpin';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    excuteSpin(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'EventSpin_excuteSpin';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    historyWinList(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'EventSpin_historyWins';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    historySpin(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'EventSpin_historySpins';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
};
export default luckySpinApi;
