import globalStyle from 'constants/global.style';
import accountStyles from '../account/account-option/account-option.style';
import { useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import game3rdApi from 'apis/game3rdApi';
import { RootState, useAppSelector } from 'cache/store';
import { Game3rdTransferModel } from 'models/fundTransferModel';
import { Game3rdTransfer, MiniGameTransfer } from './GameTransfer';
import styles from './fund-transfer.style';
import toast from 'react-hot-toast';
import { removeSeperateNumber } from 'utils/function';
import LoadingIcon from 'components/loading/LoadingIcon';
import { GameTabTransfer } from 'models/commonModel';
import miniGameApi from 'apis/miniGameApi';
import useBalance from 'hooks/useBalance';

interface GroupTransfer {
    name: string;
    gametype: string;
    child: Game3rdTransferModel[];
}

function FundTransfer() {
    const listGameInit = useRef<Game3rdTransferModel[]>([]);
    const tabRef = useRef<String>('');
    const initOptionTransfer = useRef(false);

    const { userInfo } = useAppSelector((state: RootState) => state.auth);
    const { menuGame } = useAppSelector((state: RootState) => state.system);

    const [accountFrom, setAccountFrom] = useState<'MAIN' | string>('MAIN');
    const [accountTo, setAccountTo] = useState<'MAIN' | string>('');
    const [amount, setAmount] = useState('');
    const [group3rdGameList, setGroup3rdGameList] = useState<GroupTransfer[]>([]);
    const [gameSelect, setGameSelect] = useState<Game3rdTransferModel>({} as Game3rdTransferModel);
    const [balanceTotal, setBalanceTotal] = useState(0); // số dư tài khoản chính
    const [loading, setLoading] = useState(false);

    const [optionTransferList, setOptionTransferList] = useState<Game3rdTransferModel[]>([]); // option chuyển quỹ

    const [tabTransferList, setTabTransferList] = useState<GameTabTransfer[]>([]); // tab chuyển quỹ
    const [tabTransferActive, setTabTransferActive] = useState<GameTabTransfer>({} as GameTabTransfer); // tab chuyển quỹ active
    const [miniGameData, setMiniGameData] = useState<Game3rdTransferModel>({} as Game3rdTransferModel); //mini game data. balance, name...

    const loadBalanceMain = useBalance();

    //init danh sách tab chuyển quỹ
    useEffect(() => {
        if (menuGame.length > 0) {
            let tabGroup: any[] = menuGame.filter((tab) => tab.gametype && tab.child && tab.child.length > 0);
            tabGroup = tabGroup.map((item: any) => ({ name: item.name, gametype: item.gametype }));
            tabGroup = [{ gametype: 'MINIGAME', name: 'Alobet Game' }, ...tabGroup, { gametype: 'ALL', name: 'Tất cả' }];

            initTransfer(tabGroup[0]);
            setTabTransferList(tabGroup);
        }
    }, [menuGame]);

    // set tab đầu tiên khi danh sách tab thay đổi
    useEffect(() => {
        if (tabTransferList.length > 0) {
            setTabTransferActive(tabTransferList[0]);
            tabRef.current = tabTransferList[0].gametype;
        }
    }, [tabTransferList]);

    //thay đổi danh sách option chuyển quỹ khi tab thay đổi
    useEffect(() => {
        if (tabTransferActive.gametype && listGameInit.current.length > 0) {
            initListTransfer(tabTransferActive);
        }
    }, [tabTransferActive]);

    useEffect(() => {
        if (optionTransferList.length > 0 && !initOptionTransfer.current) {
            initOptionTransfer.current = true;
            setAccountTo(optionTransferList[0].providercode);
        }

        optionTransferList.forEach((game) => {
            loadBalance3rd(game, false);
        });
    }, [optionTransferList]);

    useEffect(() => {
        if (accountTo !== 'MAIN') {
            const game3rdActive = optionTransferList.find((game) => game.providercode === accountTo);
            if (game3rdActive) {
                setGameSelect(game3rdActive);
            }
        }
    }, [accountTo]);

    //cập nhật tổng số dư khi thay đổi danh sách chuyển quỹ và tài khoản chính
    useEffect(() => {
        if (optionTransferList.length > 0 && userInfo.balance) {
            const totalBalance = optionTransferList.reduce((acc, game) => {
                let balanceGameItem = 0;
                if (game.balance) {
                    balanceGameItem = Number(game.balance);
                }
                return acc + balanceGameItem;
            }, Number(userInfo.balance));

            setBalanceTotal(totalBalance);
        }
    }, [optionTransferList, userInfo]);

    const initTransfer = async (tab: GameTabTransfer) => {
        try {
            const { data } = await game3rdApi.initTransfer({});

            if (!data.msg) {
                const miniGameWallet = { balance: 0, gametype: 'MINIGAME', providercode: 'MINIGAME', msg: '', balancename: 'Alobet Game' };
                listGameInit.current = [...data.walletItems, miniGameWallet];
                initListTransfer(tab);
                if (data.walletItems.length > 0) {
                    setAccountTo(data.walletItems[0].providercode);
                    setGameSelect(data.walletItems[0]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const initListTransfer = (tab: GameTabTransfer) => {
        let gameListActive: Game3rdTransferModel[] = [];
        if (tabTransferActive.gametype === 'ALL') {
            gameListActive = listGameInit.current;
        } else {
            gameListActive = [...listGameInit.current].filter((game) => game.gametype === tab.gametype);
        }
        setOptionTransferList(gameListActive);
    };

    const loadBalance3rd = async (gameData: Game3rdTransferModel, isReload: boolean, cb?: () => void) => {
        const gameMap = listGameInit.current.find((game) => game.providercode === gameData.providercode && game.gametype === gameData.gametype);
        mapGameLoadedIntoGroupTransfer(gameData, true);

        //case: lấy balance data từ cache
        if (!isReload && gameMap && gameMap.isLoaded) {
            updateBalanceGame(gameMap, cb);
        } else {
            if (gameData.providercode === 'MINIGAME') {
                try {
                    const { data }: any = await miniGameApi.checkBalance({});
                    if (!data.msg) {
                        setOptionTransferList((list) => {
                            return list.map((item) => {
                                if (item.providercode === 'MINIGAME') return { ...item, balance: data.balance };
                                return item;
                            });
                        });
                        updateBalanceGame({ ...gameData, ...data, isLoaded: true }, cb);
                    } else {
                        toast.error(data.msg);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    const { data }: any = await game3rdApi.checkBalance({ providercode: gameData.providercode });

                    updateBalanceGame({ ...gameData, ...data, isLoaded: true }, cb);
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    const mapGameLoadedIntoGroupTransfer = (gameData: Game3rdTransferModel, isLoading: boolean) => {
        let groupTransfer: GroupTransfer[] = menuGame.filter((item) => !item.isMain).map((item) => ({ name: item.name, gametype: item.gametype || '', child: [] }));

        if (tabRef.current !== 'ALL') {
            groupTransfer = groupTransfer.filter((item) => item.gametype === tabRef.current);
        }

        groupTransfer = groupTransfer.map((group) => {
            let groupGameList: Game3rdTransferModel[] = [];

            listGameInit.current.forEach((game) => {
                if (game.gametype === group.gametype) {
                    groupGameList.push(game);
                }
            });

            //turn off loading balance
            groupGameList = groupGameList.map((game) => {
                if (game.providercode === gameData.providercode) {
                    return { ...game, loading: isLoading };
                }
                return game;
            });

            return { ...group, child: groupGameList };
        });
        setGroup3rdGameList(groupTransfer);
    };

    const changeAccountTo = (value: string) => {
        if (value === 'MAIN') {
            const listTransferAvailable = [...optionTransferList].filter((item) => !item.msg);
            if (listTransferAvailable.length > 0) {
                setAccountFrom(listTransferAvailable[0]?.providercode);
            } else {
                setAccountFrom('');
            }
        } else {
            setAccountFrom('MAIN');
        }
        setAccountTo(value);
    };

    const changeAccountFrom = (value: string) => {
        if (value === 'MAIN') {
            const listTransferAvailable = [...optionTransferList].filter((item) => !item.msg);
            if (listTransferAvailable.length > 0) {
                setAccountTo(listTransferAvailable[0].providercode);
            } else {
                setAccountTo('');
            }
        } else {
            setAccountTo('MAIN');
        }
        setAccountFrom(value);
    };

    const updateBalanceGame = (data: Game3rdTransferModel, cb?: () => void) => {
        listGameInit.current = listGameInit.current.map((game) => {
            if (game.providercode === data.providercode && game.gametype === data.gametype) {
                return data;
            }
            return game;
        });
        if (data.providercode === 'MINIGAME') {
            setMiniGameData(data);
        } else {
            mapGameLoadedIntoGroupTransfer(data, false);
        }

        if (cb) cb();
    };

    const submitTransfer = async () => {
        if (!amount) {
            toast.error('Vui lòng nhập số tiền');
            return;
        }
        const amountTransfer = Number(removeSeperateNumber(amount));

        //deposit
        if (accountFrom === 'MAIN') {
            if (Number(userInfo.balance) < amountTransfer) {
                toast.error('Tài khoản chính không đủ');
                return;
            }
            const gameSelect = optionTransferList.find((game) => game.providercode === accountTo);
            if (gameSelect?.msg) {
                toast.error('Game được chọn đang bảo trì!');
                return;
            }

            if (amountTransfer % 1000 > 0) {
                toast.error('Số tiền giao dịch phải chia hết cho 1,000');
                return;
            }
            if (amountTransfer < 1e3) {
                toast.error('Số tiền giao dịch nhỏ nhất là 1,000');
                return;
            }
            // if (amountTransfer > 5e8) {
            //     toast.error('Số tiền giao dịch nhiều nhất là 500,000,000');
            //     return;
            // }
        }

        //withdraw
        if (accountTo === 'MAIN') {
            const gameSelect = listGameInit.current.find((game) => game.providercode === accountFrom);

            if (gameSelect?.msg) {
                toast.error('Game được chọn đang bảo trì!');
                return;
            }

            if (gameSelect && Number(gameSelect.balance) < amountTransfer) {
                toast.error('Số dư game được chọn không đủ');
                return;
            }

            if (amountTransfer < 1e3) {
                toast.error('Số tiền giao dịch nhỏ nhất là 1,000');
                return;
            }
            // if (amountTransfer > 1e8) {
            //     toast.error('Số tiền giao dịch nhiều nhất là 100,000,000');
            //     return;
            // }
        }

        if (gameSelect.providercode === 'MINIGAME') {
            setLoading(true);
            const payload = {
                type: accountTo === 'MAIN' ? 1 : 0, //1: chuyển quỹ tới minigame, 0: rút về tk chính
                amount: amountTransfer,
            };

            try {
                const { data } = await miniGameApi.submitTransfer(payload);
                if (!data.msg) {
                    toast.success('Giao dịch thành công');
                    setAmount('');
                    loadBalanceMain();

                    //reload balance game 3 chuyển quỹ
                    loadBalance3rd(gameSelect, true);
                } else {
                    toast.error(data.msg);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        } else {
            setLoading(true);
            const payload = {
                from: accountFrom,
                to: accountTo,
                amount: amountTransfer,
            };

            try {
                const { data } = await game3rdApi.submitTransfer(payload);
                if (!data.msg) {
                    toast.success('Giao dịch thành công');
                    setAmount('');
                    loadBalanceMain();

                    //reload balance game 3 chuyển quỹ
                    loadBalance3rd(gameSelect, true);
                } else {
                    toast.error(data.msg);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const changeTabTransfer = (tab: GameTabTransfer) => {
        setAccountFrom('MAIN');
        tabRef.current = tab.gametype;
        setTabTransferActive(tab);
    };

    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <h6 className={accountStyles.title}>Chuyển Quỹ</h6>
                <div className={styles.wrap}>
                    <div className={styles.left}>
                        <div className={accountStyles.groupInput}>
                            <label htmlFor="accountFrom">Từ Tài Khoản</label>
                            <div className={accountStyles.wrapInput}>
                                <select id="accountFrom" className={globalStyle.inputCommon} value={accountFrom} onChange={(e) => changeAccountFrom(e.target.value)}>
                                    <option value="MAIN">Tài khoản chính</option>
                                    {optionTransferList.map((item, index) => (
                                        <option value={item.providercode} key={index} disabled={Boolean(item.msg)}>
                                            {item.balancename.includes(',') ? item.balancename.replace(',', ' + ') : item.balancename}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={accountStyles.groupInput}>
                            <label htmlFor="accountTo">Tới</label>
                            <div className={accountStyles.wrapInput}>
                                <select id="accountTo" className={globalStyle.inputCommon} value={accountTo} onChange={(e) => changeAccountTo(e.target.value)}>
                                    <option value="MAIN">Tài khoản chính</option>
                                    {optionTransferList.map((item, index) => (
                                        <option value={item.providercode} key={index} disabled={Boolean(item.msg)}>
                                            {item.balancename?.includes(',') ? item.balancename.replace(',', ' + ') : item.balancename}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={accountStyles.groupInput}>
                            <label htmlFor="">Số Tiền (VNĐ)</label>
                            <div className={accountStyles.wrapInput}>
                                <NumericFormat
                                    className={globalStyle.inputCommon}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    placeholder="Nhập số tiền..."
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                            {/* <TagLabel label="Ít nhất 1,000 VNĐ" /> */}
                        </div>
                        {/* {accountTo !== 'MAIN' && gameSelect.providercode !== 'MINIGAME' && (
                            <div className={styles.transferNotice}>
                                <p className={globalStyle.text.error}>Lưu ý: </p>
                                <p>
                                    1,000 vnđ [ Tài khoản chính ] = 1 điểm [ cá cược <span className={globalStyle.text.error}>{gameSelect.balancename} </span>] khi cược
                                </p>
                            </div>
                        )} */}
                        <div style={{ textAlign: 'center' }}>
                            <button type="button" className={globalStyle.btnPrimary} onClick={submitTransfer}>
                                Xác Nhận
                            </button>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.balanceMain}>
                            <p>
                                <span>Tổng số dư:</span>
                                <span>{new Intl.NumberFormat().format(balanceTotal)} VNĐ</span>
                            </p>
                            <p>
                                <span>Tài khoản chính:</span>
                                <span>{userInfo.balance ? new Intl.NumberFormat().format(Number(userInfo.balance)) : 0} VNĐ</span>
                            </p>
                        </div>
                        <div className={styles.tabTransfer}>
                            {tabTransferList.map((tab) => (
                                <div className={`tab-item ${tabTransferActive.gametype === tab.gametype ? 'active' : ''}`} key={tab.gametype} onClick={() => changeTabTransfer(tab)}>
                                    {tab.name}
                                </div>
                            ))}
                        </div>
                        <div className={styles.gameWrap}>
                            {(tabTransferActive.gametype === 'ALL' || tabTransferActive.gametype === 'MINIGAME') && (
                                <div className={styles.gameGroup}>
                                    {tabTransferActive.gametype === 'ALL' && <h6 className={styles.gameGroupTitle}>Alobet</h6>}
                                    <div className={styles.gameList}>
                                        <div className={styles.gameItem}>
                                            <MiniGameTransfer onLoadBalance={loadBalance3rd} gameData={miniGameData} />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {group3rdGameList.map((group, index) => (
                                <div className={styles.gameGroup} key={index}>
                                    {tabTransferActive.gametype === 'ALL' && group.child.length > 0 && <h6 className={styles.gameGroupTitle}>{group.name}</h6>}
                                    <div className={styles.gameList}>
                                        {group.child &&
                                            group.child.map((game, idx) => (
                                                <div className={styles.gameItem} key={idx}>
                                                    <Game3rdTransfer gameData={game} onLoadBalance={loadBalance3rd} />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

export default FundTransfer;
