import { css } from '@emotion/css';
import bgPage from 'assets/images/lucky-win/bg-page.png';
import { rotatingLight } from 'constants/global.style';
import { colors } from 'constants/theme';

const luckyWinStyle = {
    wrapPage: css`
        background: url(${bgPage});
        background-size: cover;
        padding-top: 50px;
        padding-bottom: 20px;
    `,
    wrapContent: css`
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 30px;
        align-items: center;

        .winner-wrap,
        .prize-wrap {
            flex: 1;
            text-align: center;
            max-width: 300px;
            position: relative;
            align-self: stretch;
            .btn-top {
                position: absolute;
                top: 20px;
                &:active {
                    transform: scale(0.9);
                }
            }
            .btn-rule {
                right: -20px;
            }
            .btn-history {
                left: -20px;
            }
        }
        .prize-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            .prize-content {
                position: relative;
                .btn-navigate-slide {
                    position: absolute;
                    top: 50%;
                    z-index: 2;
                    &:active {
                        transform: scale(0.9);
                    }
                    img {
                        width: 38px;
                    }
                    &.prev {
                        left: -18px;
                    }
                    &.next {
                        right: -18px;
                    }
                }
            }
            .slide-content {
                position: absolute;
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
                padding: 0 4px;
                display: flex;
                align-items: flex-end;
                padding-bottom: 30px;
                overflow: hidden;
                .prize-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    img {
                        width: 90%;
                        height: 200px;
                        object-fit: contain;
                    }
                    p {
                        color: ${colors.white};
                    }
                }
            }
        }
        .winner-wrap {
            display: flex;
            align-items: center;
            .winner-content {
                position: relative;
                .winner-list {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    bottom: 8px;
                    height: 250px;
                    overflow-y: auto;
                    padding: 0 8px;
                }
                .winner-item {
                    display: flex;
                    justify-content: space-between;
                    color: ${colors.white};
                    font-size: 12px;
                    padding: 8px 0;
                    border-bottom: 1px solid #00b2a4;
                    transition: 0.4s ease-out;
                    &:last-child {
                        border-bottom: none;
                    }
                    &.auto {
                        height: auto;
                        padding: 4px 0;
                    }
                    &.show {
                        height: 47px;
                        padding: 4px 0;
                    }

                    .left {
                        flex: 0 0 60%;
                    }
                    .prize-name {
                        color: #f8db78;
                    }
                }
            }
        }
        .lucky-spin {
            flex: 0 0 640px;
            width: 640px;
            position: relative;
            display: flex;
            flex-direction: column;
            .lucky-bg {
                width: 100%;
                position: relative;
                z-index: 0;
            }
            .spin-wrap {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .spin-inner {
                    width: 100%;
                    height: 100%;
                    position: relative;
                }
                .btn-excute {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    &.disabled {
                        pointer-events: none;
                        cursor: not-allowed;
                    }
                    &:active {
                        p {
                            transform: scale(0.9);
                        }
                    }
                    p {
                        font-size: 36px;
                        font-weight: 700;
                        filter: drop-shadow(0 0 12px #000000);
                    }
                    .times {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 90px;
                        }
                        span {
                            position: absolute;
                            color: ${colors.white};
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 1;
                        }
                    }
                }
                .spin-icon {
                    width: 220px;
                }
            }
            .decor-item {
                position: absolute;
                z-index: 0;
                left: 50%;
                transform: translateX(-50%);
                &.decor-1 {
                    top: -30px;
                }
                &.decor-2 {
                    bottom: 0;
                }
            }
            canvas {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                padding: 25px;
            }
        }
    `,
    happyPopup: css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .popup-bg {
            position: relative;
            z-index: 1;
        }
        .light-effect {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            animation: ${rotatingLight} 5s linear infinite;
        }
        .text-happy {
            position: absolute;
            z-index: 1;
            width: 300px;
            top: 48%;
            transform: translateY(-50%);
        }
        .gift-info {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 200px;
            bottom: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 8px;
            img {
                height: 100px;
            }
            p {
                font-size: 24px;
                font-weight: 600;
                color: ${colors.white};
            }
        }
    `,
    wrapHistory: css`
        background: linear-gradient(180deg, rgba(255, 240, 199, 1) 0%, rgba(127, 80, 0, 1) 65%, rgba(255, 223, 168, 1) 89%);
        padding: 4px;
        border-radius: 20px;
        margin-top: 30px;
        .history-inner {
            background: linear-gradient(180deg, rgba(1, 98, 89, 1) 3%, rgba(14, 54, 51, 1) 46%, rgba(2, 34, 34, 1) 100%);
            border-radius: 18px;
            position: relative;
            padding-bottom: 12px;
        }
        .heading {
            position: absolute;
            display: flex;
            justify-content: center;
            top: -30px;
            left: 0;
            width: 100%;
        }
        .history-content {
            padding-top: 32px;
        }
        .rule-content {
            color: ${colors.white};
            height: 300px;
            overflow-y: auto;
            padding: 8px;
            p {
                line-height: 1.2;
                span {
                    display: block;
                    margin-bottom: 4px;
                }
                .title {
                    margin-bottom: 8px;
                }
            }
        }
        .btn-close-popup {
            background: #aa0e11;
            color: ${colors.white};
            font-weight: 600;
            font-size: 18px;
            width: 120px;
            padding: 8px 0;
            border-radius: 8px;
            display: block;
            margin: 12px auto 0;
        }
    `,

    historySpinWrap: css`
        width: 100%;
        padding: 0 8px;
        .history-list {
            height: 280px;
            overflow-y: auto;
        }
        .history-item {
            display: flex;
            width: 100%;
            padding: 6px 0;
            border-bottom: 1px solid #045b53;
            background: #032424;
            &:last-child {
                border-bottom: none;
            }
            p {
                color: ${colors.white};
                text-align: center;

                &:first-child {
                    flex: 0 0 40%;
                }
                &:last-child {
                    flex: 0 0 60%;
                }
            }
        }
        .history-heading {
            background: linear-gradient(180deg, rgba(255, 249, 215, 1) 0%, rgba(242, 215, 162, 1) 52%, rgba(251, 174, 61, 1) 100%);
            border-radius: 8px 8px 0 0;
            p {
                color: ${colors.black};
                font-weight: 600;
            }
        }
    `,
};

export default luckyWinStyle;
