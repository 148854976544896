import { useState } from 'react';
import styles from '../account-option.style';
import { RootState, useAppDispatch, useAppSelector } from 'cache/store';
import { SubmitHandler, useForm } from 'react-hook-form';
import moment from 'moment';
import userApi from 'apis/userApi';
import LoadingIcon from 'components/loading/LoadingIcon';
import { cacheUserInfo } from 'features/authSlice';
import toast from 'react-hot-toast';
import globalStyle from 'constants/global.style';
import DatePicker from 'components/date-picker/DatePicker';

interface FormValuesAccount {
    nickname: string;
    birthDay: string;
}

export default function Persontab() {
    const dispatch = useAppDispatch();
    const { userInfo } = useAppSelector((state: RootState) => state.auth);

    const [nickname, setNickname] = useState(userInfo.nickname);
    const [birthDay, setBirthDay] = useState<Date | null>(userInfo?.birthday ? new Date(userInfo.birthday) : new Date());
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setFocus,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm<FormValuesAccount>();

    const submitChangeNickname = async () => {
        if (nickname.trim() === '') {
            setError('nickname', {
                message: 'Biệt danh không hợp lệ',
            });
            return;
        }
        if (errors['nickname']) return;

        setLoading(true);
        try {
            const { data } = await userApi.changeNickname({ userNickname: nickname });
            if (!data.msg) {
                const newUserInfo = { ...userInfo, nickname };
                dispatch(cacheUserInfo(newUserInfo));
                toast.success('Cập nhật thành công!');
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const submitChangeBirthday = async () => {
        if (!birthDay) {
            setError('birthDay', {
                message: 'Ngày sinh không hợp lệ',
            });
            return;
        }
        if (errors['birthDay']) return;
        setLoading(true);

        try {
            const { data } = await userApi.changeBirthday({ userBirthday: moment(birthDay).format('yyyy-MM-DD') });
            if (!data.msg) {
                toast.success('Cập nhật thành công!');
                const newUserInfo = { ...userInfo, birthday: moment(birthDay).format('yyyy-MM-DD') };
                dispatch(cacheUserInfo(newUserInfo));
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const formatHideInfo = (value: string) => {
        if (value) {
            return value.length > 3 ? `${value.slice(0, 4)}****${value.slice(value.length - 3, value.length)}` : `${value.slice(0, 1)}***`;
        }
        return '';
    };

    const changeInputBirthday = (date: null | Date) => {
        if (date) {
            clearErrors('birthDay');
        }
        setBirthDay(date);
    };

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success('Sao chép thành công!');
    };

    return (
        <>
            <div className={`${styles.heading} ${styles.flexbox}`}>
                <div className={`${styles.accountName} ${styles.flex50}`}>
                    <p>
                        Tên tài khoản: <span>{userInfo.loginname}</span>
                    </p>
                    <button type="button" className={`${styles.btnCopy} btn-primary`} onClick={() => copyText(userInfo.loginname)}>
                        <img src={require('assets/images/icons/copy.png')} alt="" />
                    </button>
                </div>
                <p className={styles.flex50}>
                    Cấp độ thành viên: <span>VIP {userInfo.currentlevelvip}</span>
                </p>
            </div>
            <form>
                <div className={styles.flexbox} style={{ paddingTop: '20px', paddingBottom: '8px' }}>
                    <div className={`${styles.groupInput} ${styles.flex50}`}>
                        <label htmlFor="">Biệt Danh</label>
                        <div className={styles.wrapInput}>
                            <input value={nickname} onChange={(e) => setNickname(e.target.value)} className={`input-common`} type="text" placeholder="Nhập ký tự chữ và số" />
                            <button type="button" className={styles.btnUpdateInput} onClick={submitChangeNickname}>
                                Cập Nhật
                            </button>
                        </div>
                        {/* base error  */}
                        <div className={globalStyle.errorInput}>{errors['nickname'] && <small>{errors['nickname'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                    </div>
                    <div className={`${styles.groupInput} ${styles.flex50}`}>
                        <label htmlFor="">Ngày Sinh</label>
                        <div className={styles.wrapInput}>
                            <DatePicker dateSelect={birthDay} onSelectDate={(date) => changeInputBirthday(date)} holder="Ngày sinh" yearEnd={1970} />
                            <button type="button" className={styles.btnUpdateInput} onClick={submitChangeBirthday}>
                                Cập Nhật
                            </button>
                        </div>
                        {/* base error  */}
                        <div className={globalStyle.errorInput}>{errors['birthDay'] && <small>{errors['birthDay'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                    </div>
                    <div className={`${styles.groupInput} ${styles.flex50}`}>
                        <label htmlFor="">Email</label>
                        <div className={styles.wrapInput}>
                            <input defaultValue={formatHideInfo(userInfo?.email)} className={`input-common`} type="text" disabled placeholder="Email" />
                        </div>
                    </div>
                </div>
            </form>
            {loading && <LoadingIcon />}
        </>
    );
}
